import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/Seo";
import Magento from "../../components/integration/magento/Magento";
import i18nContext from "../../components/i18n/i18n.context";
import Page from "../../components/page/Page";

function MagentoPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          title="BusinessLocator for Magento"
        />
        <Page>
          <Magento />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

MagentoPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default MagentoPage;
